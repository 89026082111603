@use 'colors';
@use 'fonts';

$font-path: '/fonts';

@font-face {
  font-family: SFPro;
  font-weight: fonts.$font-weight-s;
  src: url('#{$font-path}/sf-pro/SFPRODISPLAYREGULAR.OTF') format('opentype');
}

@font-face {
  font-family: SFPro;
  font-weight: fonts.$font-weight-md;
  src: url('#{$font-path}/sf-pro/SFPRODISPLAYMEDIUM.OTF') format('opentype');
}

@font-face {
  font-family: SFPro;
  font-weight: fonts.$font-weight-lg;
  src: url('#{$font-path}/sf-pro/SFPRODISPLAYSEMIBOLD.OTF') format('opentype');
}

@font-face {
  font-family: SFPro;
  font-weight: fonts.$font-weight-xl;
  src: url('#{$font-path}/sf-pro/SFPRODISPLAYBOLD.OTF') format('opentype');
}

html {
  font-size: 62.5%;

  body {
    font-family: fonts.$sfpro;
    font-size: 1.6rem;
    margin: 0;
    padding: 0;

    .Toastify {
      &__close-button {
        display: none;
      }

      &__toast {
        border-radius: 14px;
        font-size: 1.5rem;
        margin: 0.8rem auto;
        max-width: 27.5rem;
        text-align: center;
        top: 7.2rem;

        &-theme--colored.Toastify__toast--default {
          background-color: #ffff00 !important;
          color: #000;
        }

        &-theme--colored.Toastify__toast--error {
          background-color: #ff0000;
          color: #fff;
        }
      }
    }
  }
}
